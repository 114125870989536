import { toast as toastify } from 'react-toastify'

const position = 'top-right'

//////////////////////////////////////////
// toast
//////////////////////////////////////////
export function toast(type: string, message: string) {
  switch (type) {
    case 'error':
      toastify.error(message, {
        position: position,
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      break

    case 'success':
      toastify.success(message, {
        position: position,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      break

    case 'warning':
      toastify.warning(message, {
        position: position,
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      break

    case 'info':
      toastify.info(message, {
        position: position,
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      break

    case 'unverified-email':
      toastify.info(message, {
        position: position,
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        className: 'toast-unverified-email',
      })
      break

    default:
      toastify(message, {
        position: position,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      break
  }
}

//////////////////////////////////////////
// toastClear
//////////////////////////////////////////
export function toastClear() {
  toastify.dismiss()
}
